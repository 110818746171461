var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "개선 이행률 - 요청대상별",
            tableId: "kras01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            showDescription: true,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "description-td",
              fn: function (props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-10 col-lg-10 impr-item",
                        },
                        [
                          _c("div", { staticClass: "pt-0 pb-0 card-body" }, [
                            _c(
                              "ul",
                              { staticClass: "list-group list-group-flush" },
                              [
                                _c(
                                  "li",
                                  { staticClass: "no-padding list-group-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "widget-content p-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "widget-content-outer",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "widget-content-wrapper",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "widget-content-left",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-heading",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.row
                                                              .improveRequestDeptName
                                                          ) +
                                                            " 개선처리건 진행도"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-subheading",
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "완료 건 수 / 요청 건 수"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " : " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "toThousandFilter"
                                                              )(
                                                                props.row
                                                                  .complete
                                                              )
                                                            ) +
                                                            " / " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "toThousandFilter"
                                                              )(
                                                                props.row
                                                                  .requestTotal
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "widget-content-right",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-numbers text-focus",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.setPercent(
                                                                props.row
                                                                  .requestTotal,
                                                                props.row
                                                                  .complete
                                                              )
                                                            ) +
                                                            "% "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "no-margin widget-progress-wrapper",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "progress-bar-sm progress-bar-animated-alt progress",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "progress-bar bg-success",
                                                      style: {
                                                        width:
                                                          _vm.setPercent(
                                                            props.row
                                                              .requestTotal,
                                                            props.row.complete
                                                          ) + "%",
                                                      },
                                                      attrs: {
                                                        role: "progressbar",
                                                        "aria-valuenow": "43",
                                                        "aria-valuemin": "0",
                                                        "aria-valuemax": "100",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 impr-item",
                        },
                        [
                          _c("div", { staticClass: "pt-0 pb-0 card-body" }, [
                            _c(
                              "ul",
                              { staticClass: "list-group list-group-flush" },
                              [
                                _c(
                                  "li",
                                  { staticClass: "no-padding list-group-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "widget-content p-0" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "widget-content-outer",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "widget-content-wrapper",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "widget-content-left",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-heading",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "[" +
                                                            _vm._s(
                                                              props.row
                                                                .improveRequestDeptName
                                                            ) +
                                                            "]"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-subheading",
                                                      },
                                                      [_vm._v(" 즉시처리건 ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "widget-content-right",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "widget-numbers text-focus",
                                                        staticStyle: {
                                                          "text-align": "end",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "count-up-wrapper-2 text-focus",
                                                            staticStyle: {
                                                              "font-weight":
                                                                "800",
                                                              "padding-right":
                                                                "5px",
                                                            },
                                                          },
                                                          [
                                                            _c("ICountUp", {
                                                              attrs: {
                                                                startVal: 0,
                                                                endVal:
                                                                  props.row
                                                                    .immTotal,
                                                                decimals: 0,
                                                                duration: 4,
                                                                options:
                                                                  _vm.options,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }